@import "tools";

.content-panel {
  padding-top: 115px;
  padding-bottom: 40px;
  background-color: $colorWhite;
  position: relative;
}

.uc-banner {
  position: absolute;
  top: 0;
  left: 0;
  background-color: $colorFireEngineRed;
  z-index: 100;
  height: 83px;
  width: 100%;
  padding: 15px 20px;

  &::after {
    content: "";
    display: table;
    clear: both;
  }
}

.banner-logo {
  display: block;
  float: left;
  height: 100%;
}

.banner-logo-image {
  display: block;
  height: 100%;
  width: auto;
}

.myuc-logo {
  color: $colorWhite;
  float: right;
  font-size: uc-rem-convert(33);
  line-height: 53px;
  margin: 0;
}

.return-to-uc {
  color: $colorWhite;
  position: relative;
  top: 54px;
  float: right;
  margin-right: 70px;
}

.hidden-xs .return-to-uc {
  margin-right: 40px;
}

.content-panels {
  min-height: 100vh;
  position: relative;
}

.login-wrapper {
  height: 100vh;
}

.help-text {
  border-bottom: solid 1px $colorMercury;
  font-size: uc-rem-convert(15);
  padding: 1em 0 2em 0;
  margin-bottom: 3em;

  &.contact {
    margin-top: 2em;
  }
}

.copyright {
  font-size: uc-rem-convert(11);
  text-align: center;
  padding-top: 3em;
}

@media (min-width: 768px) {
  .uc-banner {
    padding-left: 35px;
    padding-right: 35px;
  }
}

@media (min-width: 1024px) {
  .hero-image-container {
    position: absolute;
    height: 100%;
    right: 0;
    padding: 0;
  }
}
